export default [
    {
        name: "홈",
        icon: "home",
        to: "/home"
    },
    {
        name: "지도",
        icon: "map-marked-alt",
        to: "/map"
    },
    {
        name: "드론 관리",
        icon: "pastafarianism",
        children: [
            {
                name: "드론 로그 목록",
                to: "/drone"
            },
            {
                name: "드론 관리",
                to: "/dronedevice"
            },
        ],
    },
    {
        name: "사용자 관리",
        icon: "users",
        children: [
            {
                name: "회원 목록",
                to: "/users"
            },
            {
                name: "미승인 회원 목록",
                to: "/non-users"
            },
            {
                name: "출석 관리",
                to: "/attendance"
            }
        ],
    },
    {
        name: "비행경력증명서",
        icon: "certificate",
        children: [            
            {
                name: "발급하기",
                to: "/certificateissue"
            },
            // {
            //     name: "발급하기2",
            //     to: "/certificateissuetest"
            // },
            {
                name: "발급현황",
                to: "/certificatelist"
            }
        ],
    },
    {
        name: "기체비행기록부",
        icon: "book",
        children: [           
            {
                name: "발급하기",
                to: "/logbookissue"
            },            
            {
                name: "발급현황",
                to: "/logbooklist"
            }
        ],
    },
    {
        name: "설정",
        icon: "cog",
        children: [
            {
                name: "세션타임아웃",
                to: "/sessionTimeout"
            },
            {
                name: "비밀번호 변경",
                to: "/password"
            },
            {
                name: "버전 정보",
                to: "/version"
            },
        ],
    },
];
