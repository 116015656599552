<template>
  <div class="layout">
    <mdb-side-nav-2
        :value="true"
        :data="navigation"
        slim
        :slim-collapsed="collapsed"
        @toggleSlim="collapsed = $event"
        sidenav-class="unique-color-dark"
        color="black"
        @itemClick="selectItem"
    >
      <div slot="header">
        <div class="p-1">
          <img
              src="@/assets/logo.png"
              class="img-fluid"
              alt="null"/>
        </div>
        <hr/>
      </div>
      <div slot="content" class="mt-5 d-flex justify-content-center">
        <mdb-btn tag="a" class="mx-0" size="sm" transparent floating
                 :icon="collapsed ? 'chevron-right' : 'chevron-left'" icon-class="white-text"
                 @click="collapseLock"></mdb-btn>
      </div>

      <mdb-navbar
          slot="nav"
          tag="div"
          :toggler="false"
          position="top"
          color="unique-color-dark"
      >
        <mdb-navbar-nav class="nav-flex-icons" right>
          <mdb-nav-item waves-fixed icon="user-circle" class="userName">
            <span class="ml-1 ">{{ userName }}</span>
          </mdb-nav-item>
          <div class="width40"></div>
          <mdb-nav-item waves-fixed icon="sign-out-alt" class="logout" @click="showAlert">
            <span class="ml-1 ">로그아웃</span>
          </mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar>

      <div slot="main" class="main">
        <router-view class="main-contents"></router-view>
      </div>
    </mdb-side-nav-2>
  </div>
</template>

<script>
import {mdbSideNav2, mdbBtn, waves, mdbNavbar, mdbNavItem, mdbNavbarNav,} from "mdbvue";
import adminNavigation from "@/assets/data/adminNavigation";

export default {
  name: "MasterView",
  components: {
    mdbSideNav2,
    mdbBtn,
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
  },
  data() {
    return {
      lock: false,
      show: true,
      collapsed: false,
      navigation: adminNavigation,
      userName: '관리자님',
      isShowModal: false
    };
  },
  methods: {
    selectItem() {
      if (!this.lock) {
        // this.collapsed = true
      }
    },

    collapseLock() {
      this.collapsed = !this.collapsed
      this.lock = !this.collapsed;
    },

    showAlert() {
      const self = this;
      self.$swal.fire({
        title: '로그아웃 할까요?',
        text: "확인을 누르시면 로그아웃 합니다!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      })
          .then((result) => {
            if (result.isConfirmed) {
              self.$swal.fire('로그아웃!', '로그아웃 되었습니다.', 'success')
                  .then(() => {
                    delete localStorage.token;
                    self.$router.replace({path: '/'}).catch(() => true)
                  })
            }
          })
    },
  },
  mixins: [waves]
};
</script>

<style scoped>

/deep/ .unique-color-dark {
  background: #e6f2ff !important;
}

.width40 {
  width: 40px;
}

.main-contents {
  margin-top: 56px;
  padding-bottom: 56px;
  min-height: calc(100vh - 56px);
}

/deep/ .userName .navbar-link {
  padding-right: 0 !important;
  font-weight: 500;
}

/deep/ .logout .navbar-link {
  padding-left: 0 !important;
  font-weight: 500;
}

/deep/ .unique-color-dark {
  box-shadow: none !important;
}

/deep/ .mdbvue-sidenav__item {
  color: #868686 !important;
}

/deep/ .btn-floating i {
  color: #868686 !important;
}

/deep/ .mdbvue-sidenav__content .active {
  color: #001556 !important;
}

</style>
